import React, { useState, useRef } from 'react'
import MarkdownViewer from "@components/MarkdownViewer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const FAQDropdown = ({ label, question, answer }) => {
    const answerRef = useRef();
    
    const [answerHeight, setAnswerHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const toggleAnswer = () => {
        if (isOpen) {
            setAnswerHeight(0)
        } else {
            setAnswerHeight(answerRef.current.scrollHeight + "px")
        }
        setIsOpen(!isOpen);
    };

    const styles= {
        maxHeight: answerHeight,
    }
    
    return (
        <div class="faq__outer">
            <div class={`faq__inner ${isOpen ? "faq__open" : ""}`}>
                <p className="faq__label">{label}</p>
                <div class="faq__question" onClick={toggleAnswer}>
                    <p>{question}</p>
                    <FontAwesomeIcon icon={faChevronDown} color="#545454" />
                </div>
                
                <p 
                    class="faq__answer" 
                    style={styles} 
                    ref={answerRef}
                >
                    <MarkdownViewer markdown={answer} />
                </p>
            </div>
        </div>
    )
}

export default FAQDropdown
