// See https://marked.js.org/#/USING_ADVANCED.md#options for full list of setOptions

import React from "react";
import PropTypes from "prop-types";

var classNames = require("classnames");
var marked = require('marked');
marked.setOptions({
    renderer: new marked.Renderer(),
    smartLists: true
});

function createHtml(html) {
    return { __html: html }
}

function MarkdownViewer(props) {

    let replaceSymbols = true

    // set defaultProps
    if (props.replaceSymbols) replaceSymbols = props.replaceSymbols


    if (props.priority) {
        const CustomTag = `h${props.priority}`;
        return (
            <CustomTag className={props.className}>{props.markdown}</CustomTag>
        )
    }

    var viewerClasses = classNames("marked", props.className, {})

    if (!props.markdown) {
        throw new Error(`No 'markdown' received by MarkdownViewer. Props are as follows: ${JSON.stringify(props)}`);
    }

    return (
        <div
            className={viewerClasses}
            dangerouslySetInnerHTML={createHtml(
                marked(
                    replaceSymbols ?
                        props.markdown.replace(/[™®©]/g, '<sup>$&</sup>')
                        :
                        props.markdown
                )
            )}
        ></div>
    )
}

MarkdownViewer.propTypes = {
    className: PropTypes.string,
    markdown: PropTypes.string.isRequired
}

export default MarkdownViewer