import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FAQDropdown } from '../components/Accordion'


const FrequentlyAskedQuestions = ({ data }) => {

    const post = data.allUniquePagesJson.nodes[0]

    return (
        <Layout
          customNavButton={post.customNavButton}
          footerType={post.footerType}
        >
            <SEO
                title={post.metaTitle}
                description={post.metaDescription}
                schemaPage={null}
            />
            
            <div className="container nav-spacer">
                <h1 className="has-text-centered">{post.heading}</h1>

              <div className="section">
                {post.accordion.map((item, i) => (
                    <div key={i}>
                      <FAQDropdown
                        label={item.label}
                        question={item.question}
                        answer={item.answer}
                      />
                    </div>
                  ))}
              </div>
                
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
query faqQuery ($title: String!)  {
  allUniquePagesJson(filter: {title: {eq: $title}}) {
    nodes {
      metaTitle
      metaDescription
      heading
      accordion {
        label
        question
        answer
      }
      customNavButton {
        title
        href
        type
        hasButton
      }
      footerType
    }
  }
}`;

export default FrequentlyAskedQuestions
